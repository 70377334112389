import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import {
  getAccount,
  getFactory,
  getProvider,
  getRouter,
  getSigner,
  getBalanceAndSymbol,
  getReserves,
} from "../../services/ethereumFunctions";
import config, { notify } from "../../config/config";
import { addLiquidity, quoteAddLiquidity } from "./LiquidityFunctions";
import { removeLiquidity, quoteRemoveLiquidity } from "./LiquidityFunctions";
import { tokensArrayFixed } from "../../services/constants";

import "../../assets/hypdex.css";
import "../../assets/hypdex-responsive.css";
import { jQueryFunc, loadPage } from "../../assets/js/hypdex.js";
import { withTranslation } from "react-i18next";

function Liquidity(props) {
  const { t } = props;

  if (typeof window !== "object") {
    return null;
  }

  const [deploy, setDeploy] = useState(true);

  const [provider] = useState(getProvider());
  const [signer] = useState(getSigner(provider));
  const [account, setAccount] = useState(undefined); // This is populated in a react hook
  const [router] = useState(getRouter(config.RouterContract, signer));

  const [factory] = useState(getFactory(config.FactoryContract, signer));

  const [coin1, setCoin1] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [coin2, setCoin2] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [reserves, setReserves] = useState(["0.0", "0.0"]);
  const [liquidity_tokens, setLiquidity_tokens] = useState("");
  const [tokensOut, setTokensOut] = useState([0, 0, 0]);
  const [field1Value, setField1Value] = useState("");
  const [field2Value, setField2Value] = useState("");
  const [loading, setLoading] = useState(false);
  const [liquidity_out, setLiquidity_out] = useState([0, 0, 0]);
  const [changedField, setChangedField] = useState(0);
  const switchFields = () => {
    setCoin1(coin2);
    setCoin2(coin1);
    setField1Value(field2Value);
    setReserves(reserves.reverse());
  };
  const handleChange = {
    field1: (e) => {
      setField1Value(e.target.value);
      setChangedField(1);
    },
    field2: (e) => {
      setField2Value(e.target.value);
      setChangedField(2);
    },
  };

  const formatBalance = (balance, symbol) => {
    if (balance && symbol) return parseFloat(balance).toPrecision(8);
    else return "0.0000";
  };

  const formatReserve = (reserve, symbol) => {
    if (reserve && symbol) return reserve;
    else return "0.0000";
  };

  const validKeyPress = (e) => {
    let validFloat = new RegExp("^[0-9]*[.]?[0-9]*$");
    if (e.keyCode === 8) return;
    if (!validFloat.test(e.key)) {
      e.preventDefault();
    }
  };

  const isButtonEnabled = React.useCallback(() => {
    let validFloat = new RegExp("^[0-9]*[.]?[0-9]*$");
    return (
      coin1.address &&
      coin2.address &&
      validFloat.test(field1Value) &&
      parseFloat(field1Value) <= coin1.balance &&
      !isNaN(parseFloat(field1Value))
    );
  }, [coin1.address, coin2.address, field1Value, coin1.balance]);

  const onToken1Selected = (address) => {
    if (address === coin2.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(account, address, provider, signer).then((data) => {
        setCoin1({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const onToken2Selected = (address) => {
    if (address === coin1.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(account, address, provider, signer).then((data) => {
        setCoin2({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const add = () => {
    if (loading || !isButtonEnabled()) return;

    setLoading(true);
    addLiquidity(
      coin1.address,
      coin2.address,
      parseFloat(field1Value),
      parseFloat(field2Value),
      0,
      0,
      router,
      account,
      signer
    )
      .then(() => {
        setLoading(false);
        setField1Value("");
        setField2Value("");
        notify.success("Deployment successful");
      })
      .catch((e) => {
        setLoading(false);
        notify.error("Deployment failed (" + e.message + ")");
      });
  };

  const remove = () => {
    setLoading(true);
    removeLiquidity(
      coin1.address,
      coin2.address,
      parseFloat(field1Value),
      0,
      0,
      router,
      account,
      signer,
      factory
    )
      .then(() => {
        setLoading(false);
        setField1Value("");
        notify.success("Removal successful");
      })
      .catch((e) => {
        setLoading(false);
        notify.error("Deployment Failed (" + e.message + ")");
      });
  };

  const switchDeploy = () => {
    console.log("sw");
    setDeploy(!deploy);
  };

  useEffect(() => {
    if (coin1.address && coin2.address && account) {
      getReserves(coin1.address, coin2.address, factory, signer, account).then(
        (data) => {
          setReserves([data[0], data[1]]);
          setLiquidity_tokens(data[2]);
        }
      );
    }
  }, [coin1.address, coin2.address, account, factory, signer]);

  useEffect(() => {
    if (
      coin1.address &&
      coin2.address &&
      reserves[0] !== 0 &&
      reserves[1] !== 0
    ) {
      if (isButtonEnabled() && deploy && changedField !== 0) {
        let cf = changedField;
        setChangedField(0);

        const field1ValueVal = field1Value && cf === 1 ? +field1Value : 0;
        const field2ValueVal = field2Value && cf === 2 ? +field2Value : 0;
        quoteAddLiquidity(
          coin1.address,
          coin2.address,
          parseFloat(field1ValueVal),
          parseFloat(field2ValueVal),
          factory,
          signer
        ).then((data) => {
          setLiquidity_out([data[0], data[1], data[2]]);
          if (cf === 1) setField2Value(data[1] === 0 ? "" : data[1]);
          else if (cf === 2) setField1Value(data[0] === 0 ? "" : data[0]);
        });
      } else if (isButtonEnabled() && !deploy) {
        quoteRemoveLiquidity(
          coin1.address,
          coin2.address,
          field1Value,
          factory,
          signer
        ).then((data) => {
          setTokensOut(data);
        });
      }
    }
  }, [
    coin1.address,
    coin2.address,
    field1Value,
    field2Value,
    changedField,
    factory,
    signer,
    deploy,
    isButtonEnabled,
    reserves,
  ]);

  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      if (coin1.address && coin2.address && account) {
        getReserves(
          coin1.address,
          coin2.address,
          factory,
          signer,
          account
        ).then((data) => {
          setReserves([data[0], data[1]]);
          setLiquidity_tokens(data[2]);
        });
      }
      if (coin1 && account) {
        getBalanceAndSymbol(account, coin1.address, provider, signer).then(
          (data) => {
            setCoin1({
              ...coin1,
              balance: data.balance,
            });
          }
        );
      }
      if (coin2 && account) {
        getBalanceAndSymbol(account, coin2.address, provider, signer).then(
          (data) => {
            setCoin2({
              ...coin2,
              balance: data.balance,
            });
          }
        );
      }
    }, 10000);
    return () => clearTimeout(coinTimeout);
  });

  useEffect(() => {
    jQueryFunc();
    loadPage(window.location.pathname + window.location.search);
    getAccount().then((account) => {
      setAccount(account);
    });
  }, []);

  return (
    <>
      <section className="page-header p-b-0">
        <div className="page-header-bg"></div>

        <div className="page-header-shape-1"></div>
        <div className="page-header-shape-2"></div>
        <div className="page-header-shape-3"></div>

        <div className="container text-center">
          <div className="page-header__inner">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className=" ">
                  <div className="cube">
                    <div className="s1"></div>
                    <div className="s2"></div>
                    <div className="s3"></div>
                    <div className="s4"></div>
                    <div className="s5"></div>
                    <div className="s6"></div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <h2>LIQUIDITY</h2>
                <hr />
                <p>
                  {t(
                    "Users may stake liquidity into HyperDex Token Swap pools in return for a percentage of the swap fees. This is done by staking two tokens into the respective liquidity pool in return for an LP token. LP tokens represent a user’s proportional share of the liquidity pool, and are used to redeem staked liquidity."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hyperdex_cubes">
        <div className="container text-center"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <figure className="hypdex-logo">
                  <div className="cubeHyp">
                    <div className="face face-front"></div>
                    <div className="face face-front-in"></div>
                    <div className="face face-back"></div>
                    <div className="face face-back-in"></div>
                    <div className="face face-left"></div>
                    <div className="face face-left-in"></div>
                    <div className="face face-right"></div>
                    <div className="face face-right-in"></div>
                    <div className="face face-top"></div>
                    <div className="face face-top-in"></div>
                  </div>
                </figure>

                <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12 mx-auto text-center">
                  <h2
                    className=""
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginBottom: "1em",
                      marginTop: "1em",
                    }}
                  >
                    {deploy ? "ADD Liquidity" : "Remove Liquidity"}
                  </h2>
                  <div className="col-md-12 m-t-20">
                    <div>
                      <input
                        type="text"
                        id="field1"
                        className="text-center form-control"
                        placeholder="0.0"
                        name="input_amount"
                        value={field1Value}
                        onKeyDown={validKeyPress}
                        onChange={handleChange.field1}
                      />
                    </div>
                    <select
                      name=""
                      className="thm-btn comment-form__btn padding_select m-t-20 text-center"
                      id=""
                      onChange={(e) =>
                        onToken1Selected(
                          e.target.options[e.target.options.selectedIndex].value
                        )
                      }
                    >
                      <option key="select" value="">
                        {t("Select")}
                      </option>
                      {/* {tokensArrayFixed.map((item) => (
                        <option
                          key={item.token.name}
                          value={item.token.address}
                        >
                          {item.token.name}
                        </option>
                      ))} */}
                    </select>
                  </div>
                  <h2
                    className="top"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {deploy ? (
                      account ? (
                        <Link
                          to="#"
                          className={
                            loading || !isButtonEnabled() ? "disabled" : ""
                          }
                          onClick={add}
                        >
                          <b style={{ color: "#ffa0f8" }}>
                            ADD <i className="fas fa-plus"></i>
                          </b>
                        </Link>
                      ) : (
                        <p>{t("Connect your wallet to add liquidity")}</p>
                      )
                    ) : account ? (
                      <Link
                        to="#"
                        className={
                          loading || !isButtonEnabled() ? "disabled" : ""
                        }
                        onClick={remove}
                      >
                        <b style={{ color: "#ffa0f8" }}>
                          REMOVE <i className="fas fa-minus"></i>
                        </b>
                      </Link>
                    ) : (
                      <p>{t("Connect your wallet to remove liquidity")}</p>
                    )}
                  </h2>
                  <div className="col-md-12 m-t-20">
                    <select
                      name=""
                      className="thm-btn comment-form__btn padding_select m-t-20 text-center"
                      id=""
                      onChange={(e) =>
                        onToken2Selected(
                          e.target.options[e.target.options.selectedIndex].value
                        )
                      }
                    >
                      <option key="select" value="">
                        {t("Select")}
                      </option>
                      {/* {tokensArrayFixed.map((item) => (
                        <option
                          key={item.token.name}
                          value={item.token.address}
                        >
                          {item.token.name}
                        </option>
                      ))} */}
                    </select>

                    <div className="m-t-20">
                      <input
                        type="text"
                        id="field1"
                        className="text-center form-control"
                        placeholder="0.0"
                        name="input_amount"
                        value={field2Value}
                        onKeyDown={validKeyPress}
                        onChange={handleChange.field2}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 m-t-20 d-block text-center">
              <div
                style={
                  deploy
                    ? { backgroundColor: "white", color: "#a73296" }
                    : { background: "#a73296" }
                }
                className="thm-btn padding_select text-center"
                onClick={switchDeploy}
              >
                Add
              </div>
              <div
                style={
                  !deploy
                    ? { backgroundColor: "white", color: "#a73296" }
                    : { background: "#a73296" }
                }
                className="thm-btn comment-form__btn padding_select text-center"
                onClick={switchDeploy}
              >
                Remove
              </div>
            </div>
            <div className="col-md-6 m-t-20">
              <div className="row">
                <h3
                  className="p-0 m-0"
                  style={{
                    textAlign: "center",
                    color: "white",
                    margin: "1em 0 1em 0",
                  }}
                >
                  {t("Your wallet balance")}{" "}
                </h3>
                <div className="col-md-6">
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {coin1.symbol || "..."}
                  </p>
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatBalance(coin1.balance, coin1.symbol)}
                  </p>
                </div>
                <div className="col-md-6">
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {coin2.symbol || "..."}
                  </p>
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatBalance(coin2.balance, coin2.symbol)}
                  </p>
                </div>

                <h3
                  className="p-0 m-0"
                  style={{
                    textAlign: "center",
                    color: "white",
                    margin: "1em 0 1em 0",
                  }}
                >
                  {t("Reserves")}{" "}
                </h3>
                <div className="col-md-6">
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {coin1.symbol || "..."}
                  </p>
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatReserve(reserves[0], coin1.symbol)}
                  </p>
                </div>
                <div className="col-md-6">
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {coin2.symbol || "..."}
                  </p>
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatReserve(reserves[1], coin2.symbol)}
                  </p>
                </div>
                <h3
                  className="p-0 m-0"
                  style={{
                    textAlign: "center",
                    color: "white",
                    margin: "1em 0 1em 0",
                  }}
                >
                  {t("Your LP Tokens")}{" "}
                </h3>
                <div className="col-md-12 text-center">
                  <p
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {formatReserve(liquidity_tokens, "UNI-V2")}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 m-t-20">
              {deploy ? (
                <div className="row">
                  <h3
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {t("Tokens In")}{" "}
                  </h3>
                  <div className="col-md-6">
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {coin1.symbol || "..."}
                    </p>
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {formatReserve(reserves[0], coin1.symbol)}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {coin2.symbol || "..."}
                    </p>
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {formatReserve(reserves[1], coin2.symbol)}
                    </p>
                  </div>
                  <h3
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {t("LP Tokens Out")}{" "}
                  </h3>
                  <div className="col-md-12 text-center">
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {formatReserve(liquidity_out[2], "UNI-V2")}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <h3
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {t("LP Tokens In")}{" "}
                  </h3>
                  <div className="col-md-12 text-center">
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {formatBalance(tokensOut[0], "UNI-V2")}
                    </p>
                  </div>
                  <h3
                    className="p-0 m-0"
                    style={{
                      textAlign: "center",
                      color: "white",
                      margin: "1em 0 1em 0",
                    }}
                  >
                    {t("Tokens Out")}{" "}
                  </h3>
                  <div className="col-md-6">
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {coin1.symbol || "..."}
                    </p>
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {formatBalance(tokensOut[1], coin1.symbol)}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {coin2.symbol || "..."}
                    </p>
                    <p
                      className="p-0 m-0"
                      style={{
                        textAlign: "center",
                        color: "white",
                        margin: "1em 0 1em 0",
                      }}
                    >
                      {formatBalance(tokensOut[2], coin2.symbol)}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withTranslation()(Liquidity);

import React from "react";
import { graphql } from "gatsby";
import Liquidity from "../../modules/liquidity/index";
const LiquidityPage = () => <Liquidity />;

export default LiquidityPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
